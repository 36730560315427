import { AddressUpsert } from "@fscrypto/domain/wallet";
import { Button } from "@fscrypto/ui";
import { COMPASS_WALLET, FIN_WALLET, LEAP_WALLET, SeiWallet } from "@sei-js/core";
import { SeiWalletProvider } from "@sei-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AlertTriangleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { WagmiProvider, createConfig, http, useConnect, useDisconnect, useSignMessage, useSwitchChain } from "wagmi";
import { mainnet } from "wagmi/chains";
import { SIGN_MESSAGE } from "~/utils/wallet-connectors/sei";

const queryClient = new QueryClient();

const CHAIN_ID = "pacific-1";

export const SeiWalletConnectors = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  return (
    <SeiWalletProvider
      chainConfiguration={{
        chainId: "pacific-1",
        restUrl: "https://rest.wallet.pacific-1.sei.io/",
        rpcUrl: "https://rpc.wallet.pacific-1.sei.io/",
      }}
      wallets={["compass", "fin", "leap"]}
    >
      <div className="flex w-fit items-center rounded-md bg-amber-200/10 p-2">
        <AlertTriangleIcon className="mx-2 hidden h-5 w-5 text-yellow-700 md:block" />
        <p className="my-2 text-yellow-700">EVM wallets (e.g. MetaMask) cannot be used for payments</p>
      </div>
      <WalletSelector setAddress={setAddress} />
    </SeiWalletProvider>
  );
};

const WalletSelector = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  return (
    <div className="scrollbar mt-4 max-h-[600px] space-y-2 overflow-y-scroll">
      {[COMPASS_WALLET, FIN_WALLET, LEAP_WALLET].map((w) => {
        return <WalletCard key={w.walletInfo.name} wallet={w} setAddress={setAddress} />;
      })}
      <EvmWalletConnectors setAddress={setAddress} />
    </div>
  );
};

const WalletCard = ({ wallet, setAddress }: { wallet: SeiWallet; setAddress: (address: AddressUpsert) => void }) => {
  const { windowKey, name, icon, website } = wallet.walletInfo;

  // @ts-ignore
  const isInstalled = !!window[windowKey];

  return (
    <div className="mx-auto flex w-full items-center justify-between rounded-lg border border-gray-200 p-4">
      <div className="items flex cursor-pointer flex-row space-x-2">
        <img src={icon} alt={name} className="m-1 h-5 w-5 flex-shrink-0" />
        <span>{name}</span>
      </div>
      {!isInstalled && (
        <Button variant="secondary" className="w-24" onClick={() => window.open(website, "_blank")}>
          Install
        </Button>
      )}
      {isInstalled && (
        <Button
          variant="primary"
          className="w-24"
          onClick={async () => {
            const y = await wallet.getOfflineSigner(CHAIN_ID);
            const z = await y?.getAccounts();
            const stdSign = await wallet.signArbitrary?.(CHAIN_ID, z![0].address, SIGN_MESSAGE);
            if (!stdSign) return;

            setAddress({
              address: z![0].address,
              chain: "sei",
              isVerified: true,
              isDefault: true,
              connectorMeta: {
                type: "Sei",
                subType: {
                  type: "sei",
                  publicKey: {
                    type: stdSign.pub_key.type,
                    value: stdSign.pub_key.value,
                  },
                  signature: stdSign.signature,
                },
              },
            });
          }}
        >
          Connect
        </Button>
      )}
    </div>
  );
};

const EvmWalletConnectors = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  return (
    <WagmiProvider
      config={createConfig({
        chains: [
          mainnet,
          {
            id: 1329,
            name: "Sei",
            iconUrl: "/sei.png",
            nativeCurrency: {
              decimals: 18,
              name: "Sei",
              symbol: "SEI",
            },
            rpcUrls: {
              public: {
                http: [" https://evm-rpc.sei-apis.com"],
              },
              default: {
                http: [" https://evm-rpc.sei-apis.com"],
              },
            },
            blockExplorers: {
              etherscan: { name: "Sei Block Explorer", url: "https://seitrace.com/" },
              default: { name: "Sei Block Explorer", url: "https://seitrace.com/" },
            },
          },
        ],
        transports: {
          [mainnet.id]: http(),
          1329: http(),
        },
      })}
    >
      <QueryClientProvider client={queryClient}>
        <MetaMaskCard setAddress={setAddress} />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

const MetaMaskCard = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  const { connectors, connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const { switchChainAsync } = useSwitchChain();
  const [metamaskIsInstalled, setMetamaskIsInstalled] = useState<boolean>();

  const metaMaskConnector = connectors.find((connector) => connector.name === "MetaMask");

  // Check if metamask is installed
  useEffect(() => {
    const a = async () => {
      const p = await metaMaskConnector?.getProvider();
      if (p !== undefined) {
        setMetamaskIsInstalled(true);
      }
    };
    a();
  }, [metaMaskConnector]);

  const connectOnClick = async () => {
    await disconnectAsync(); // disconnect any existing connections
    if (!metaMaskConnector) {
      // assert
      console.error("MetaMask connector not found");
      return;
    }
    const connection = await connectAsync({ connector: metaMaskConnector, chainId: 713715 });
    const address = connection.accounts[0];
    await switchChainAsync({ chainId: 1329, connector: metaMaskConnector });
    const signature = await signMessageAsync({ message: SIGN_MESSAGE, connector: metaMaskConnector, account: address });
    setAddress({
      address,
      chain: "sei",
      isVerified: true,
      isDefault: true,
      isPayable: false, // EVM wallets cannot be used for payments
      connectorMeta: {
        type: "Sei",
        subType: {
          type: "evm",
          signature,
        },
      },
    });
  };

  return (
    <div className="mx-auto flex w-full items-center justify-between rounded-lg border border-gray-200 p-4">
      <div className="items flex cursor-pointer flex-row space-x-2">
        <img
          src={"https://iconic.dynamic-static-assets.com/icons/sprite.svg#metamask"}
          alt={"MetaMask"}
          className="m-1 h-5 w-5 flex-shrink-0"
        />
        <span>MetaMask</span>
      </div>
      {!metamaskIsInstalled && (
        <Button variant="secondary" className="w-24" onClick={() => window.open("https://metamask.io", "_blank")}>
          Install
        </Button>
      )}
      {metamaskIsInstalled && (
        <Button variant="primary" className="w-24" onClick={connectOnClick}>
          Connect
        </Button>
      )}
    </div>
  );
};
