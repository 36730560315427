import { assign } from "xstate";
import { type SystemKey, createProfileManagerMachine, Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import { Team } from "@fscrypto/domain/team";
import { createActor } from "~/state/actor";

export type EventBusEvent = { type: "GLOBAL.PROFILE_MANAGER.ACCEPT_INVITATION"; payload: { team: Team } };

export const createProfileManagerActor = createActor(
  { createMachine: createProfileManagerMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      declineInvitationEpic: (ctx, e) => {
        epic.send({
          type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION",
          payload: {
            invitationId: e.payload.invitationId,
          },
        });
      },
      handleDeclinePendingInvitationSuccess: assign((ctx, e) => {
        if (!ctx.invites) return {};
        const { invitationId } = e.payload;
        return {
          invites: ctx.invites.filter((i) => i.id !== invitationId),
        };
      }),
      acceptInvitationEpic: (ctx, e) => {
        epic.send({
          type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION",
          payload: e.payload,
        });
      },
      handleAcceptPendingInvitationSuccess: assign((ctx, e) => {
        if (!ctx.invites || !ctx.teams) return {};
        const { invitationId, team } = e.payload;
        return {
          invites: ctx.invites.filter((i) => i.id !== invitationId),
          teams: [...ctx.teams, team],
        };
      }),
      broadcastAcceptInvitation: (ctx, e) => {
        eventBus.send({
          type: "GLOBAL.PROFILE_MANAGER.ACCEPT_INVITATION",
          payload: {
            team: e.payload.team,
          },
        });
      },
      createTeamEpic: (ctx, e) => {
        epic.send({ type: "PROFILE_MANAGER.EPIC.CREATE", payload: { ...e.payload, verified: true } });
      },
      persistCallback: assign((ctx, e) => {
        return {
          callback: e.callback,
        };
      }),
      executeCallback: assign((ctx) => {
        ctx.callback?.(ctx);
        return {
          callback: null,
        };
      }),
      handleCreateTeamSuccess: assign((ctx, e) => {
        if (!ctx.teams) return {};
        return {
          teams: [...ctx.teams, e.payload],
        };
      }),
      updateTeam: assign((ctx, e) => {
        if (!ctx.teams) return {};
        const { team } = e.payload;
        return {
          teams: ctx.teams.map((t) => (t.id === team.id ? team : t)),
        };
      }),
    },
    guards: {},
  }),
);

export type Actor = ReturnType<typeof createProfileManagerActor>;

export const getProfileManagerActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
