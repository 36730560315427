import { AddressUpsert } from "@fscrypto/domain/wallet";
import { Button, Spinner } from "@fscrypto/ui";
import { setupCoin98Wallet } from "@near-wallet-selector/coin98-wallet";
import { ModuleState, Wallet, WalletSelector, setupWalletSelector } from "@near-wallet-selector/core";
import { SignMessageMethod } from "@near-wallet-selector/core/src/lib/wallet";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupMathWallet } from "@near-wallet-selector/math-wallet";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupNearMobileWallet } from "@near-wallet-selector/near-mobile-wallet";
import { setupNearFi } from "@near-wallet-selector/nearfi";
import { setupNightly } from "@near-wallet-selector/nightly";
import { setupSender } from "@near-wallet-selector/sender";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { setupWelldoneWallet } from "@near-wallet-selector/welldone-wallet";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
  MY_NEAR_WALLET_SESSION_TOKEN_KEY,
  getNearNonce,
  nearRecipient,
  nearSignMessage,
} from "~/utils/wallet-connectors/near";

// https://docs.near.org/build/web3-apps/backend/#2-ask-the-user-to-sign-the-challenge
export const NearWalletConnectors = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  const [selector, setSelector] = useState<WalletSelector>();
  useEffect(() => {
    // Need to set this up in useEffect to avoid the server side from trying to render this
    setupWalletSelector({
      network: "mainnet",
      modules: [
        // recommended wallets from https://wallet.near.org/
        setupMyNearWallet(),
        setupMeteorWallet(),
        setupSender(),
        setupHereWallet(),
        setupNightly(),
        setupWelldoneWallet(),
        setupNearMobileWallet(),
        // others...
        setupMathWallet(),
        setupNearFi(),
        setupCoin98Wallet(),
        setupWalletConnect({
          projectId: "3535c4a9c639d9a3145101ae6d5cce63",
          metadata: {
            name: "NEAR Wallet Selector",
            description: "Flipside Crypto <> Near",
            url: "https://github.com/near/wallet-selector",
            icons: ["https://avatars.githubusercontent.com/u/37784886"],
          },
        }),
      ],
    }).then((selector) => {
      setSelector(selector);
    });
  }, []);

  const modules = selector?.store.getState().modules;

  if (!modules || !selector) return null; //bootstrapping

  return (
    <div className="scrollbar mt-4 max-h-[600px] space-y-2 overflow-y-scroll">
      {modules.map((m) => (
        <Module key={m.id} module={m} walletSelector={selector} setAddress={setAddress} />
      ))}
    </div>
  );
};

const Module = ({
  module,
  walletSelector,
  setAddress,
}: {
  module: ModuleState<Wallet>;
  walletSelector: WalletSelector;
  setAddress: (address: AddressUpsert) => void;
}) => {
  const { id } = module;
  const { name, iconUrl } = module.metadata;

  // @ts-ignore downloadUrl is not in the type for some reason
  const downloadUrl: string | undefined = module.metadata.downloadUrl;

  const [buttonState, setButtonState] = useState<"sign" | "loading" | "install">("loading");
  const [wallet, setWallet] = useState<Wallet & SignMessageMethod>();

  useEffect(() => {
    const fn = async () => {
      try {
        const wallet = await walletSelector.wallet(id);
        setWallet(wallet);
        setButtonState("sign");
      } catch (e) {
        console.warn(e);
        // Probably missing the wallet
        setButtonState("install");
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickConnect = () => {
    // MyNearWallet is special because its a web-based wallet
    const isMyNearWallet = id === "my-near-wallet";
    let callbackUrl;
    if (isMyNearWallet) {
      const myNearWalletSessionToken = nanoid();
      localStorage.setItem(MY_NEAR_WALLET_SESSION_TOKEN_KEY, myNearWalletSessionToken);
      callbackUrl = `${window.location.protocol}//${window.location.host}/my-near-wallet-connect/${myNearWalletSessionToken}`;
    }
    wallet
      ?.signMessage({
        message: nearSignMessage,
        recipient: nearRecipient,
        nonce: getNearNonce(),
        callbackUrl,
        state: isMyNearWallet
          ? JSON.stringify({
              callbackUrl,
              redirectUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
            })
          : undefined,
      })
      .then((s) => {
        // not sure why this would be void
        if (!s) return;

        setAddress({
          address: s.accountId,
          chain: "near",
          connectorMeta: {
            type: "Near",
            publicKey: s.publicKey,
            signature: s.signature,
          },
          isDefault: true,
          isVerified: true,
        });
      });
  };

  return (
    <div className="mx-auto flex w-full items-center justify-between rounded-lg border border-gray-200 p-4">
      <img src={iconUrl} alt={name} className="m-1 h-5 w-5 flex-shrink-0" />
      <span>{name}</span>
      {buttonState === "loading" && <Spinner />}
      {buttonState === "install" && (
        <Button variant="secondary" className="w-24" onClick={() => window.open(downloadUrl, "_blank")}>
          Install
        </Button>
      )}
      {buttonState === "sign" && (
        <Button variant="primary" className="w-24" onClick={onClickConnect}>
          Connect
        </Button>
      )}
    </div>
  );
};
