import { rewards as rewardsDomain, team } from "@fscrypto/domain";
import { Drawer, DrawerContent, DrawerTitle, TabList, TabListItem } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { sortBy } from "lodash-es";
import { ExternalLink } from "lucide-react";
import { useState } from "react";
import { tracking } from "~/utils/tracking";
import { useRewards } from "../state/use-rewards";
import { HistoryCard } from "./history-card";
import { RewardCard } from "./reward-card";
import { useRewardsDrawer } from "../state/use-reward-drawer";

export interface RewardDrawerProps {
  userTeams: team.Team[];
}
export const RewardDrawer = ({ userTeams }: RewardDrawerProps) => {
  const rewardsDrawer = useRewardsDrawer();
  const [pending, setPending] = useState<boolean>(true);
  const rewards = useRewards();
  const userRewards = rewards.rewardOverview?.userRewards ?? [];
  const paymentHistory = rewards.rewardOverview?.paymentHistory;
  const sortedHistory = sortBy(paymentHistory, (payment) => payment.paymentRecord.createdAt).reverse();
  const activeRewards = sortBy(
    userRewards.filter((r) => !rewardsDomain.RewardsService.isClaimed(r)),
    (r) => r.createdAt,
  ).reverse();
  return (
    <Drawer open={rewardsDrawer.open} onOpenChange={(o) => rewardsDrawer.setOpen(o)}>
      <DrawerContent>
        <DrawerTitle>Rewards</DrawerTitle>
        <div className="flex items-end justify-between">
          <TabList className="mt-4">
            <TabListItem onClick={() => setPending(true)} active={pending}>
              Pending
            </TabListItem>
            <TabListItem
              onClick={() => {
                setPending(false);
                tracking("click_history_tab", "Earn");
              }}
              active={!pending}
            >
              History
            </TabListItem>
          </TabList>
          <Link to="/settings/addresses" className="flex flex-row items-center gap-1 text-xs text-blue-500">
            Manage Addresses <ExternalLink className="size-4" />
          </Link>
        </div>
        <div className="scrollbar mt-4 max-h-[90%] space-y-4 overflow-y-scroll p-0.5">
          {pending ? (
            activeRewards.length > 0 ? (
              activeRewards.map((reward) => (
                <div key={reward.id}>
                  <RewardCard
                    reward={reward}
                    team={userTeams.find((t) => t.profileId === reward.profileId)}
                    showHeader={userTeams.length > 0}
                  />
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No pending rewards</p>
            )
          ) : sortedHistory.length === 0 ? (
            <p className="text-center text-gray-500">No transaction history</p>
          ) : (
            sortedHistory.map((payment) => (
              <div key={payment.paymentRecord.id}>
                <HistoryCard payment={payment} />
              </div>
            ))
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
