import type { wallet } from "@fscrypto/domain";
import { Avatar, Dropdown, Text } from "@fscrypto/ui";
import { ChevronDownIcon } from "lucide-react";
import config from "~/utils/config";

export function NetworksDropdown({
  networks,
  selected,
  onSelect,
}: {
  networks: wallet.Network[];
  selected?: wallet.Network;
  onSelect: (network: wallet.Network) => void;
}) {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="w-full">
        <div className="dark:bg-gray-90 dark:text-gray-40 relative w-full cursor-default rounded-md border bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6">
          {selected ? (
            <div className="flex flex-wrap items-center gap-2">
              <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${selected.chain}.svg`} size="sm" />
              <Text color="contrast">{selected.displayName}</Text>
            </div>
          ) : (
            <span className="text-gray-60 block truncate">{"Select a chain"}</span>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="text-muted-foreground size-5" aria-hidden="true" />
          </span>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content align="start" className="z-50 w-full min-w-[300px]">
        {networks.map((n) => {
          return (
            <Dropdown.Item key={n.chain} onClick={() => onSelect(n)}>
              <div className="flex flex-wrap items-center gap-2">
                <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${n.chain}.svg`} size="sm" />
                <Text color="contrast">{n.displayName}</Text>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Content>
    </Dropdown.Root>
  );
}
