import { member } from "@fscrypto/domain";
import { Team, TeamEditOrCreate } from "@fscrypto/domain/team";
import { TeamInvitation, TeamInvitationCreate, TeamInvitationUpdate } from "@fscrypto/domain/team-invitation";
import { User } from "@fscrypto/domain/user";
import { ofType } from "@fscrypto/state-management";

import type { Observable } from "rxjs";
import { catchError, from, map, merge, of, switchMap } from "rxjs";
import { GET, POST } from "~/async/fetch";

// Input events, triggers epics
type CreateTeam = { type: "TEAM.EPIC.CREATE"; payload: TeamEditOrCreate & { verified: true } };
type UpdateTeam = {
  type: "TEAM.EPIC.UPDATE";
  payload: { teamId: string; teamData: TeamEditOrCreate & { verified: true } };
};
type DeleteTeam = { type: "TEAM.EPIC.DELETE"; payload: { teamId: string } };
type SearchUsers = { type: "TEAM.EPIC.SEARCH_USERS"; payload: string };
type CreateTeamInvitation = { type: "TEAM.EPIC.CREATE_INVITATION"; payload: TeamInvitationCreate };
type UpdateTeamInvitation = { type: "TEAM.EPIC.UPDATE_INVITATION"; payload: TeamInvitationUpdate };
type AcceptTeamInvitation = { type: "TEAM.EPIC.ACCEPT_INVITATION"; payload: { id: string } };
type DeleteTeamInvitation = {
  type: "TEAM.EPIC.DELETE_INVITATION";
  payload: { invitationId: string; teamId: string };
};
type RemoveMember = { type: "TEAM.EPIC.REMOVE_MEMBER"; payload: { memberId: string; teamId: string } };
type UpdateMember = { type: "TEAM.EPIC.UPDATE_MEMBER"; payload: { id: string; role: member.MemberRole } };
type LeaveTeam = { type: "TEAM.EPIC.LEAVE_TEAM"; payload: { teamId: string; memberId: string } };
type RefetchTeamData = { type: "TEAM.EPIC.REFETCH_TEAM_DATA"; payload: { teamId: string } };

// Output events, sent to the machine
type CreateTeamSuccess = { type: "TEAM.EPIC.CREATE_SUCCESS"; payload: Team };
type CreateTeamFailure = { type: "TEAM.EPIC.CREATE_FAILURE"; error: Error };

type UpdateTeamSuccess = { type: "TEAM.EPIC.UPDATE_SUCCESS"; payload: Team };
type UpdateTeamFailure = { type: "TEAM.EPIC.UPDATE_FAILURE"; error: Error };

type DeleteTeamSuccess = { type: "TEAM.EPIC.DELETE_SUCCESS"; payload: string };
type DeleteTeamFailure = { type: "TEAM.EPIC.DELETE_FAILURE"; error: Error };

type SearchUsersSuccess = { type: "TEAM.EPIC.SEARCH_USERS_SUCCESS"; payload: { users: User[] } };
type SearchUsersFailure = { type: "TEAM.EPIC.SEARCH_USERS_FAILURE"; error: Error };

type CreateTeamInvitationSuccess = { type: "TEAM.EPIC.CREATE_INVITATION_SUCCESS"; payload: TeamInvitation };
type CreateTeamInvitationFailure = { type: "TEAM.EPIC.CREATE_INVITATION_FAILURE"; error: Error };

type UpdateTeamInvitationSuccess = {
  type: "TEAM.EPIC.UPDATE_INVITATION_SUCCESS";
  payload: TeamInvitation;
};
type UpdateTeamInvitationFailure = { type: "TEAM.EPIC.UPDATE_INVITATION_FAILURE"; error: Error };

type AcceptTeamInvitationSuccess = {
  type: "TEAM.EPIC.ACCEPT_INVITATION_SUCCESS";
  payload: { team: Team; invitationId: string };
};
type AcceptTeamInvitationFailure = { type: "TEAM.EPIC.ACCEPT_INVITATION_FAILURE"; error: Error };

type DeleteTeamInvitationSuccess = {
  type: "TEAM.EPIC.DELETE_INVITATION_SUCCESS";
  payload: { invitationId: string; teamId: string };
};
type DeleteTeamInvitationFailure = { type: "TEAM.EPIC.DELETE_INVITATION_FAILURE"; error: Error };

type RemoveMemberSuccess = {
  type: "TEAM.EPIC.REMOVE_MEMBER_SUCCESS";
  payload: { teamId: string; memberId: string };
};
type RemoveMemberFailure = { type: "TEAM.EPIC.REMOVE_MEMBER_FAILURE"; error: Error };

type UpdateMemberSuccess = { type: "TEAM.EPIC.UPDATE_MEMBER_SUCCESS"; payload: member.Member };
type UpdateMemberFailure = { type: "TEAM.EPIC.UPDATE_MEMBER_FAILURE"; error: Error };

type LeaveTeamSuccess = { type: "TEAM.EPIC.LEAVE_TEAM_SUCCESS"; payload: string };
type LeaveTeamFailure = { type: "TEAM.EPIC.LEAVE_TEAM_FAILURE"; error: Error };

type RefetchTeamDataSuccess = { type: "TEAM.EPIC.REFETCH_TEAM_DATA_SUCCESS"; payload: Team };
type RefetchTeamDataFailure = { type: "TEAM.EPIC.REFETCH_TEAM_DATA_FAILURE"; error: Error };

export type OutputEvent =
  | CreateTeamSuccess
  | CreateTeamFailure
  | UpdateTeamSuccess
  | UpdateTeamFailure
  | DeleteTeamSuccess
  | DeleteTeamFailure
  | SearchUsersSuccess
  | SearchUsersFailure
  | CreateTeamInvitationSuccess
  | CreateTeamInvitationFailure
  | DeleteTeamInvitationSuccess
  | DeleteTeamInvitationFailure
  | UpdateTeamInvitationSuccess
  | UpdateTeamInvitationFailure
  | AcceptTeamInvitationSuccess
  | AcceptTeamInvitationFailure
  | RemoveMemberSuccess
  | RemoveMemberFailure
  | UpdateMemberSuccess
  | UpdateMemberFailure
  | LeaveTeamSuccess
  | LeaveTeamFailure
  | RefetchTeamDataSuccess
  | RefetchTeamDataFailure;

type InputEvent =
  | CreateTeam
  | UpdateTeam
  | DeleteTeam
  | SearchUsers
  | CreateTeamInvitation
  | DeleteTeamInvitation
  | UpdateTeamInvitation
  | AcceptTeamInvitation
  | RemoveMember
  | UpdateMember
  | LeaveTeam
  | RefetchTeamData;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(
    createTeamEpic(actions$),
    updateTeamEpic(actions$),
    deleteTeamEpics(actions$),
    searchUsersEpic(actions$),
    createTeamInvitationEpic(actions$),
    deleteTeamInvitationEpic(actions$),
    updateInvitationEpic(actions$),
    acceptTeamInvitationEpic(actions$),
    removeMemberEpic(actions$),
    updateMemberEpic(actions$),
    leaveTeamEpic(actions$),
    refetchTeamDataEpic(actions$),
  );
};

const createTeamEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.CREATE"),
    switchMap(({ payload }) =>
      from(createTeam(payload)).pipe(
        map((r) => ({ type: "TEAM.EPIC.CREATE_SUCCESS", payload: r }) as CreateTeamSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.CREATE_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as CreateTeamFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const updateTeamEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.UPDATE"),
    switchMap(({ payload }) =>
      from(updateTeam(payload.teamId, payload.teamData)).pipe(
        map((r) => ({ type: "TEAM.EPIC.UPDATE_SUCCESS", payload: r }) as UpdateTeamSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.UPDATE_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as UpdateTeamFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const deleteTeamEpics = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.DELETE"),
    switchMap(({ payload }) =>
      from(deleteTeam(payload.teamId)).pipe(
        map(() => ({ type: "TEAM.EPIC.DELETE_SUCCESS", payload: payload.teamId }) as DeleteTeamSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.DELETE_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as DeleteTeamFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const searchUsersEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.SEARCH_USERS"),
    switchMap(({ payload }) =>
      from(searchUsers(payload)).pipe(
        map((r) => ({ type: "TEAM.EPIC.SEARCH_USERS_SUCCESS", payload: r }) as SearchUsersSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.SEARCH_USERS_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as SearchUsersFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const createTeamInvitationEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.CREATE_INVITATION"),
    switchMap(({ payload }) =>
      from(createInvite(payload)).pipe(
        map((r) => ({ type: "TEAM.EPIC.CREATE_INVITATION_SUCCESS", payload: r }) as CreateTeamInvitationSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.CREATE_INVITATION_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as CreateTeamInvitationFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const deleteTeamInvitationEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.DELETE_INVITATION"),
    switchMap(({ payload }) =>
      from(deleteInvite(payload.invitationId)).pipe(
        map(() => ({ type: "TEAM.EPIC.DELETE_INVITATION_SUCCESS", payload }) as DeleteTeamInvitationSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.DELETE_INVITATION_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as DeleteTeamInvitationFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const updateInvitationEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.UPDATE_INVITATION"),
    switchMap(({ payload }) =>
      from(updateInvite(payload)).pipe(
        map(
          (invitation) =>
            ({
              type: "TEAM.EPIC.UPDATE_INVITATION_SUCCESS",
              payload: invitation,
            }) as UpdateTeamInvitationSuccess,
        ),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.UPDATE_INVITATION_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as UpdateTeamInvitationFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const acceptTeamInvitationEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.ACCEPT_INVITATION"),
    switchMap(({ payload }) =>
      from(acceptInvite(payload)).pipe(
        map(
          (team) =>
            ({
              type: "TEAM.EPIC.ACCEPT_INVITATION_SUCCESS",
              payload: { team, invitationId: payload.id },
            }) as AcceptTeamInvitationSuccess,
        ),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.ACCEPT_INVITATION_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as AcceptTeamInvitationFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const removeMemberEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.REMOVE_MEMBER"),
    switchMap(({ payload }) =>
      from(removeMember(payload.memberId)).pipe(
        map(() => ({ type: "TEAM.EPIC.REMOVE_MEMBER_SUCCESS", payload }) as RemoveMemberSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.REMOVE_MEMBER_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as RemoveMemberFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const updateMemberEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.UPDATE_MEMBER"),
    switchMap(({ payload }) =>
      from(updateMember(payload.id, payload.role)).pipe(
        map(() => ({ type: "TEAM.EPIC.UPDATE_MEMBER_SUCCESS", payload }) as UpdateMemberSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.UPDATE_MEMBER_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as UpdateMemberFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const leaveTeamEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.LEAVE_TEAM"),
    switchMap(({ payload }) =>
      from(leaveTeam(payload.teamId)).pipe(
        map(() => ({ type: "TEAM.EPIC.LEAVE_TEAM_SUCCESS", payload: payload.memberId }) as LeaveTeamSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.LEAVE_TEAM_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as LeaveTeamFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

const refetchTeamDataEpic = (action$: Observable<InputEvent>) => {
  return action$.pipe(
    ofType("TEAM.EPIC.REFETCH_TEAM_DATA"),
    switchMap(({ payload }) =>
      from(refetchTeamData(payload.teamId)).pipe(
        map((r) => ({ type: "TEAM.EPIC.REFETCH_TEAM_DATA_SUCCESS", payload: r }) as RefetchTeamDataSuccess),
        catchError((_) =>
          of({
            type: "TEAM.EPIC.REFETCH_TEAM_DATA_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as RefetchTeamDataFailure),
        ),
      ),
    ),
  ) as Observable<OutputEvent>;
};

// ASYNC API Calls

const createTeam = async (teamData: TeamEditOrCreate) => {
  try {
    const data = await POST<Team>(`/api/teams/create`, teamData);
    return data;
  } catch (e) {
    throw new Error("Error Creating Team");
  }
};

const updateTeam = async (teamId: string, teamData: TeamEditOrCreate) => {
  try {
    const data = await POST<Team>(`/api/teams/${teamId}/update`, teamData);
    return data;
  } catch (e) {
    throw new Error("Error Updating Team");
  }
};

const deleteTeam = async (teamId: string) => {
  try {
    const id = await POST<string>(`/api/teams/${teamId}/delete`);
    return id;
  } catch (e) {
    throw new Error("Error Deleting Team");
  }
};

const searchUsers = async (search: string) => {
  try {
    const data = await GET<{ users: User[] }>(`/api/users/search/${search}?pageSize=100`);
    return data;
  } catch (e) {
    throw new Error("Error Searching Users");
  }
};

const createInvite = async (args: TeamInvitationCreate) => {
  try {
    const invite = await POST<TeamInvitation, TeamInvitationCreate>(`/api/teams-invitation/create`, args);
    return invite;
  } catch (e) {
    throw new Error("Error Creating Invite");
  }
};

const deleteInvite = async (inviteId: string) => {
  try {
    await POST<string>(`/api/teams-invitation/${inviteId}/delete`);
    return inviteId;
  } catch (e) {
    throw new Error("Error Deleting Invite");
  }
};

const acceptInvite = async (args: { id: string }) => {
  try {
    const team = await POST<Team>(`/api/teams-invitation/${args.id}/accept`);
    return team;
  } catch (e) {
    throw new Error("Error Accepting Invite");
  }
};

const updateInvite = async (args: TeamInvitationUpdate) => {
  try {
    const team = await POST<TeamInvitation, TeamInvitationUpdate>(`/api/teams-invitation/${args.id}/update`, args);
    return team;
  } catch (e) {
    throw new Error("Error Updating Invite");
  }
};

const removeMember = async (memberId: string) => {
  try {
    const id = await POST<string>(`/api/teams-member/${memberId}/delete`);
    return id;
  } catch (e) {
    throw new Error("Error Removing Memeber");
  }
};

const updateMember = async (id: string, role: string) => {
  try {
    await POST<string>(`/api/teams-member/${id}/update`, { role: role });
    return id;
  } catch (e) {
    throw new Error("Error Updating Memeber");
  }
};

const leaveTeam = async (teamId: string) => {
  try {
    const id = await POST<string>(`/api/teams-member/${teamId}/leave`);
    return id;
  } catch (e) {
    throw new Error("Error Leaving Team");
  }
};

const refetchTeamData = async (teamId: string) => {
  try {
    const data = await GET<Team>(`/api/teams/${teamId}`);
    return data;
  } catch (e) {
    throw new Error("Error Refetching Data");
  }
};
