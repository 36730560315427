import { DynamicContextProvider, DynamicEmbeddedWidget, EvmNetwork, mergeNetworks } from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
// Ordering here matters, @dynamic-labs/sdk-react-core polyfills Global and Process which are needed by the cosmos connectors
import { CosmosWalletConnectors } from "@dynamic-labs/cosmos";
import { FlowWalletConnectors } from "@dynamic-labs/flow";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import type { wallet } from "@fscrypto/domain";
import { AddressUpsert } from "@fscrypto/domain/wallet";
import { useUserStateMachine } from "~/state/machines/user-state/user-state";
import { useMemo } from "react";

const customEVMNetworks = [
  {
    chainId: 8217,
    networkId: 8217,
    name: "Klaytn",
    chainName: "cypress",
    blockExplorerUrls: ["https://scope.klaytn.com/"],
    rpcUrls: ["https://node-api.klaytnapi.com/v1/klaytn"],
    nativeCurrency: {
      name: "KLAY",
      symbol: "KLAY",
      decimals: 18,
    },
    iconUrls: ["https://assets.coingecko.com/coins/images/9672/large/klaytn.png?1611558142"],
  },
] as EvmNetwork[];

export const Dynamic = ({
  network,
  setAddress,
}: {
  network: wallet.Network;
  setAddress: (address: AddressUpsert) => void;
}) => {
  const { theme } = useUserStateMachine();
  const walletConnectors = useMemo(() => {
    if (network.chain === "solana") return [SolanaWalletConnectors];
    if (network.chain === "flow") return [FlowWalletConnectors];
    if (network.chain === "axelar") return [CosmosWalletConnectors];
    return [EthereumWalletConnectors];
  }, [network.chain]);

  return (
    <div className="mt-4">
      <DynamicContextProvider
        theme={theme}
        settings={{
          environmentId: window.ENV.DYNAMICXYZ_ENV_ID,
          overrides: {
            evmNetworks: (networks) => mergeNetworks(customEVMNetworks, networks),
          },
          walletConnectors,
          // Hide the redundant "Select Wallet" header
          cssOverrides: `
          .modal-header > h1 { display: none; }
          .modal-header { padding-top: 0px; padding-bottom: 0px; }
          .default-footer__footer { display: none; }
`,
          eventsCallbacks: {
            onAuthSuccess: (event) => {
              const lastVerifiedCredentialId = event.user.lastVerifiedCredentialId;
              const lastVerifiedCredential = event.user.verifiedCredentials.find(
                (vc) => vc.id === lastVerifiedCredentialId,
              );
              if (!lastVerifiedCredential || !lastVerifiedCredential.address) return;

              setAddress({
                address: lastVerifiedCredential.address,
                chain: network.chain,
                connectorMeta: {
                  type: "Dynamic",
                  authToken: event.authToken,
                },
                isDefault: true,
                isVerified: true,
              });

              // we don't need Dynamic anymore at this point
              event.handleLogOut();
            },
          },
        }}
      >
        <DynamicEmbeddedWidget />
      </DynamicContextProvider>
    </div>
  );
};
