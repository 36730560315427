import { Team, TeamEditOrCreate } from "@fscrypto/domain/team";
import { HttpClient, Client } from "@fscrypto/http";

class ClientProfileManager {
  constructor(private readonly client: HttpClient) {
    this.client = client;
  }
  async createTeam(teamData: TeamEditOrCreate): Promise<Team> {
    return this.client.post<Team>("/api/teams/create", teamData);
  }
  async declineInvitation(inviteId: string): Promise<string> {
    await this.client.post<string>(`/api/teams-invitation/${inviteId}/decline`, {});
    return inviteId;
  }
  async acceptInvite(invitationId: string): Promise<{ team: Team; invitationId: string }> {
    const teamData = await this.client.post<Team>(`/api/teams-invitation/${invitationId}/accept`, {});
    return {
      team: teamData,
      invitationId,
    };
  }
}

export const profileManagerClient = new ClientProfileManager(new Client());
