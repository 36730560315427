// import { CurrentUser } from "@fscrypto/domain";
// import { Team } from "@fscrypto/domain/team";

import { CurrentUser } from "@fscrypto/domain";
import { Team } from "@fscrypto/domain/team";

// import { CurrentUser } from "@fscrypto/domain";
// import { Team } from "@fscrypto/domain/team";

// export type UserProfile = CurrentUser | Team;

// export function resolveCurrentProfile(
//   currentUser: CurrentUser | undefined,
//   teamsArray: Team[] | undefined,
// ): { currentProfile: UserProfile | undefined } {
//   const currentProfileId = currentUser?.currentProfileId!;
//   const currentProfile =
//     currentUser?.profileId === currentProfileId
//       ? currentUser
//       : teamsArray?.find((team) => team.profileId === currentProfileId);

//   return { currentProfile };
// }

export type UserProfile = CurrentUser | Team;

export type ProfileResolution = {
  currentProfile: UserProfile | undefined;
  otherAccounts: UserProfile[];
};

export function resolveCurrentProfile(
  currentUser: CurrentUser | undefined,
  teamsArray: Team[] | undefined,
): ProfileResolution {
  const currentProfileId = currentUser?.currentProfileId!;
  let currentProfile: UserProfile | undefined;
  let otherAccounts: UserProfile[] = [];

  if (currentUser?.profileId === currentProfileId) {
    currentProfile = currentUser;
    if (teamsArray) {
      otherAccounts = teamsArray;
    }
  } else {
    currentProfile = teamsArray?.find((team) => team.profileId === currentProfileId);
    if (currentUser) {
      otherAccounts.push(currentUser);
    }
    if (teamsArray) {
      otherAccounts = otherAccounts.concat(teamsArray.filter((team) => team.profileId !== currentProfileId));
    }
  }

  return { currentProfile, otherAccounts };
}
