import React from "react";
import { Text } from "../../primitives/text/text";
import { LogoIcon } from "../../primitives/logo";
import { Button } from "../../components/button/button";
import type { User } from "@fscrypto/domain/user";
import { cn } from "../../utils/cn";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/dropdown-menu/dropdown-menu";
import { ChevronDown, MenuIcon } from "lucide-react";
import { type Team } from "@fscrypto/domain/team";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/avatar/avatar";

interface HeaderProps {
  rewardCount?: number;
  renderMenuContent?: React.ReactNode;
  authMenuContent?: React.ReactNode;
  user?: User | null;
  team?: Team | null;
  dark?: boolean;
  transparent?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  rewardCount = 0,
  renderMenuContent,
  authMenuContent,
  user,
  team,
  dark,
  transparent,
}) => {
  return (
    <header
      className={cn("bg-bg-primary w-full relative z-30 bg-bg-black py-6 md:py-4", {
        dark: dark,
        "bg-transparent": !!transparent,
        "bg-bg-primary": !transparent,
      })}
    >
      <div className="w-full items-center justify-between px-10 hidden min-[1090px]:flex">
        <HeaderLogo />
        <HeaderLeftNav />
        <NavDivider />
        <HeaderRightNav />
        <HeaderAuth
          rewardCount={rewardCount}
          renderMenuContent={renderMenuContent}
          authMenuContent={authMenuContent}
          user={user}
          team={team}
        />
      </div>
      <MobileNav user={user} />
    </header>
  );
};

const HeaderLogo: React.FC = () => (
  <a href="/" aria-label="Flipside" className="flex items-center space-x-2">
    <LogoIcon className="h-8 w-auto" />
    <h1 className="text-xl">flipside</h1>
  </a>
);

const NavDivider: React.FC = () => <div className="h-8 w-px bg-border-secondary" />;

const HeaderLeftNav: React.FC = () => (
  <nav className="hidden space-x-4 md:flex">
    <HeaderNavItem href="/insights/dashboards">Insights</HeaderNavItem>
    <HeaderNavItem href="/edit">Studio</HeaderNavItem>
    <HeaderNavItem href="/earn/">Quests</HeaderNavItem>
  </nav>
);

const HeaderRightNav: React.FC = () => (
  <nav className="hidden space-x-4 md:flex justify-between flex-1 max-w-[600px]">
    <HeaderNavItem href="/home/chains">For Chains</HeaderNavItem>
    <HeaderNavItem href="/home/analysts">For Analysts</HeaderNavItem>
    <HeaderNavItem href="/home/explorers">For Explorers</HeaderNavItem>
    <AboutMenu />
  </nav>
);

interface HeaderNavItemProps {
  href: string;
  children: React.ReactNode;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ href, children }) => (
  <a href={href} className="text-sm block px-2 relative font-medium">
    {children}
  </a>
);

const HeaderAuth: React.FC<HeaderProps> = ({ rewardCount = 0, team, renderMenuContent, authMenuContent, user }) => (
  <div className="flex items-center space-x-4">
    {authMenuContent && <div className="ml-2">{authMenuContent}</div>}
    {user ? (
      <>
        <HeaderNavItem href="/rewards">
          Rewards
          {rewardCount > 0 && (
            <div className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-500 px-1 text-xs text-white">
              {rewardCount}
            </div>
          )}
        </HeaderNavItem>
        <UserMenu user={user} team={team} renderMenuContent={renderMenuContent} />
      </>
    ) : (
      <>
        <HeaderAuthButton variant="ghost" href="/home/login">
          Log in
        </HeaderAuthButton>
        <HeaderAuthButton variant="default" href="/home/login?screen_hint=signup">
          Sign up
        </HeaderAuthButton>
      </>
    )}
  </div>
);

interface HeaderAuthButtonProps {
  variant: "default" | "ghost" | "outline";
  href: string;
  children: React.ReactNode;
  className?: string;
}

const HeaderAuthButton: React.FC<HeaderAuthButtonProps> = ({ variant, href, children, className }) => (
  <a href={href}>
    <Button variant={variant} className={className}>
      {children}
    </Button>
  </a>
);

interface UserMenuProps {
  renderMenuContent?: React.ReactNode;
  user: User;
  team?: Team | null;
}

const UserMenu: React.FC<UserMenuProps> = ({ user, team, renderMenuContent }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <div className="items-center cursor-pointer flex space-x-1">
        <Avatar className="size-6">
          <AvatarImage src={user.avatarUrl} alt={user.username} />
          <AvatarFallback>{user.username.charAt(0).toUpperCase()}</AvatarFallback>
        </Avatar>
        {team && (
          <div className="bg-bg-tertiary/50 p-1 pr-3 rounded-full flex items-center space-x-2 text-sm">
            <Avatar className="size-6">
              <AvatarImage src={team.avatarUrl} />
              <AvatarFallback>{team.name.charAt(0).toUpperCase()}</AvatarFallback>
            </Avatar>
            <span>{team.name}</span>
          </div>
        )}
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-60">
      <div className="px-3 py-2 text-sm font-normal">
        <div className="flex flex-col space-y-1">
          <p>{user.username}</p>
          <p className="text-text-tertiary">{user.email}</p>
        </div>
      </div>
      {renderMenuContent && (
        <>
          <DropdownMenuSeparator />
          {renderMenuContent}
        </>
      )}
      <DropdownMenuSeparator />
      {team && (
        <DropdownMenuItem asChild>
          <a className="h-full w-full cursor-pointer" href={`/${team.slug}`}>
            Team Profile
          </a>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href={`/${user.username}`}>
          Profile
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href={`/settings/addresses`}>
          Wallets
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/settings/plan">
          Settings
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/rewards">
          Rewards
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/earn/history">
          Quest History
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <form action="/home/logout" method="post">
          <button type="submit" className="h-full w-full cursor-pointer text-left">
            Log out
          </button>
        </form>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

export const AboutMenu = () => (
  <DropdownMenu>
    <DropdownMenuTrigger>
      <div className="flex items-center space-x-1 px-7 cursor-pointer">
        <span className="text-sm font-medium">About</span>
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      <DropdownMenuItem>
        <a href="/home/about">Company</a>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <a href="https://flipsidecrypto.xyz/fc/resources" target="_blank">
          Resources
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <a href="https://docs.flipsidecrypto.xyz/" target="_blank">
          Documentation
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <a href="https://flipsidecrypto.breezy.hr/" target="_blank">
          Careers
        </a>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

interface MobileMenuProps {
  user?: User | null;
}

const MobileNav: React.FC<MobileMenuProps> = ({ user }) => (
  <div className="min-[1090px]:hidden w-full flex items-center justify-between px-4">
    <HeaderLogo />
    <div className="flex items-center space-x-1">
      <MobileMenu user={user} />
      {user && <UserMenu user={user} />}
    </div>
  </div>
);

const MobileMenu: React.FC<MobileMenuProps> = ({ user }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost">
        <MenuIcon className="h-6 w-6" /> {/* Use MenuIcon as the trigger */}
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="flex flex-col justify-between p-0">
      <div className="flex flex-col">
        <DropdownMenuItem>
          <a href="/insights/dashboards">
            <Text variant="button">Data</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/earn/">
            <Text variant="button">Quests</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/chains">
            <Text variant="button">For Chains</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/analysts">
            <Text variant="button">For Analysts</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/explorers">
            <Text variant="button">For Explorers</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/about">
            <Text variant="button">About</Text>
          </a>
        </DropdownMenuItem>
      </div>
      {!user && (
        <div className="flex p-4 gap-x-4">
          <>
            <HeaderAuthButton variant="outline" href="/home/login" className="w-full max-w-full">
              Log in
            </HeaderAuthButton>
            <HeaderAuthButton variant="default" href="/home/login?screen_hint=signup" className="w-full max-w-full">
              Sign up
            </HeaderAuthButton>
          </>
        </div>
      )}
    </DropdownMenuContent>
  </DropdownMenu>
);
