import { useCurrentUser } from "~/features/current-user";
import { resolveCurrentProfile } from "./resolve-current-profile";
import { Team } from "@fscrypto/domain/team";
import { isUser } from "./is-user";

export const useCurrentProfileData = (teams: Team[]) => {
  const { currentUser } = useCurrentUser();
  const { currentProfile, otherAccounts } = resolveCurrentProfile(currentUser, teams);
  const url = isUser(currentProfile) ? `/${currentProfile.username}` : `/teams/${currentProfile?.slug}`;
  return {
    currentProfile,
    url,
    otherAccounts,
    isTeam: !isUser(currentProfile),
  };
};
