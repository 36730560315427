import { Team, TeamEditOrCreate } from "@fscrypto/domain/team";
import { Observable, merge } from "rxjs";
import { profileManagerClient } from "../data/profile-manager-client";
import { createSimpleEpic } from "@fscrypto/state-management";

// Input events, triggers epics
type CreateTeam = { type: "PROFILE_MANAGER.EPIC.CREATE"; payload: TeamEditOrCreate & { verified: true } };
type AcceptTeamInvitation = { type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION"; payload: { id: string } };
type DeclineTeamInvitation = {
  type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION";
  payload: { invitationId: string };
};

type CreateTeamSuccess = { type: "PROFILE_MANAGER.EPIC.CREATE_SUCCESS"; payload: Team };
type CreateTeamFailure = { type: "PROFILE_MANAGER.EPIC.CREATE_FAILURE"; error: Error };
type AcceptTeamInvitationSuccess = {
  type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION_SUCCESS";
  payload: { team: Team; invitationId: string };
};
type AcceptTeamInvitationFailure = { type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION_FAILURE"; error: Error };

type DeclineTeamInvitationSuccess = {
  type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION_SUCCESS";
  payload: { invitationId: string };
};
type DeclineTeamInvitationFailure = { type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION_FAILURE"; error: Error };

export type OutputEvent =
  | AcceptTeamInvitationSuccess
  | AcceptTeamInvitationFailure
  | DeclineTeamInvitationSuccess
  | DeclineTeamInvitationFailure
  | CreateTeamSuccess
  | CreateTeamFailure;

type InputEvent = AcceptTeamInvitation | DeclineTeamInvitation | CreateTeam;

export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(createTeamEpic(actions$), acceptInviteEpic(actions$), declineTeamInvitationEpic(actions$));
};

const createTeamEpic = createSimpleEpic<CreateTeam, Team, CreateTeamSuccess, CreateTeamFailure>({
  ofType: "PROFILE_MANAGER.EPIC.CREATE",
  performTask: (payload) => profileManagerClient.createTeam(payload),
  onSuccess: (payload) => ({ type: "PROFILE_MANAGER.EPIC.CREATE_SUCCESS", payload }),
  onFailure: (error) => ({ type: "PROFILE_MANAGER.EPIC.CREATE_FAILURE", error: new Error("Unable to Create Team") }),
});

const acceptInviteEpic = createSimpleEpic<
  AcceptTeamInvitation,
  { team: Team; invitationId: string },
  AcceptTeamInvitationSuccess,
  AcceptTeamInvitationFailure
>({
  ofType: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION",
  performTask: (payload) => profileManagerClient.acceptInvite(payload.id),
  onSuccess: (payload) => ({ type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION_SUCCESS", payload }),
  onFailure: (error) => ({
    type: "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION_FAILURE",
    error: new Error("Unable to Accept Team Invitation"),
  }),
});

const declineTeamInvitationEpic = createSimpleEpic<
  DeclineTeamInvitation,
  string,
  DeclineTeamInvitationSuccess,
  DeclineTeamInvitationFailure
>({
  ofType: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION",
  performTask: (payload) => profileManagerClient.declineInvitation(payload.invitationId),
  onSuccess: (payload) => ({
    type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION_SUCCESS",
    payload: { invitationId: payload },
  }),
  onFailure: (error) => ({
    type: "PROFILE_MANAGER.EPIC.DECLINE_INVITATION_FAILURE",
    error: new Error("Unable to Delete Team Invitation"),
  }),
});
