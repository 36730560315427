import { assign } from "xstate";
import { createActor } from "~/state/actor";
import { type SystemKey, createTeamsMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import { Team } from "@fscrypto/domain/team";

export type EventBusEvent = { type: "GLOBAL.TEAM.UPDATE"; payload: { team: Team } };

export const createTeamsActor = createActor(
  { createMachine: createTeamsMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      persistCallback: assign((ctx, e) => {
        return {
          callback: e.callBack,
        };
      }),
      executeCallback: assign((ctx) => {
        ctx.callback?.(ctx);
        return {
          callback: null,
        };
      }),
      updateTeamEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.UPDATE",
          payload: {
            teamId: ctx.team.id,
            teamData: {
              ...e.payload.teamData,
              verified: true,
            },
          },
        });
      },
      handleUpdateTeamSuccess: assign((ctx, e) => {
        const updatedTeam = e.payload;
        return {
          team: updatedTeam,
          updateCallback: undefined,
        };
      }),
      broadcastUpdate: (ctx) => {
        eventBus.send({
          type: "GLOBAL.TEAM.UPDATE",
          payload: { team: ctx.team },
        });
      },
      deleteTeamEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.DELETE",
          payload: {
            teamId: e.payload.id,
          },
        });
      },
      handleDeleteTeamSuccess: (ctx) => {
        actorSystem.unregister(`teams-${ctx.team.id}`);
      },
      searchUsersEpic: (ctx, e) => {
        if (e.payload.length < 3) return;
        epic.send({
          type: "TEAM.EPIC.SEARCH_USERS",
          payload: e.payload,
        });
      },
      handleSearchUsersSuccess: assign((ctx, e) => {
        return {
          filteredUsers: e.payload.users,
        };
      }),
      createInvitationEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.CREATE_INVITATION",
          payload: {
            teamId: ctx.team.id,
            invitedUserIdOrEmail: e.payload.invitedUserIdOrEmail,
            role: e.payload.role,
          },
        });
      },
      handleCreateInvitationFailure: (_) => {
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: {
            title: "Error Creating Invitation",
            type: "error",
            description: "There was an error creating the invitation",
          },
        });
      },
      updateInvitationEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.UPDATE_INVITATION",
          payload: {
            id: e.payload.id,
            status: e.payload.status,
            role: e.payload.role,
          },
        });
      },
      handleUpdateInvitationSuccess: assign((ctx, e) => {
        const updatedInvitation = e.payload;
        const { team } = ctx;

        const newInvitations = team.invitations.map((invite) =>
          invite.id === updatedInvitation.id ? updatedInvitation : invite,
        );

        return {
          team: { ...team, invitations: newInvitations },
        };
      }),

      handleCreateInvitationSuccess: assign((ctx, e) => {
        const invitation = e.payload;
        const { team } = ctx;
        return {
          team: { ...team, invitations: [...team.invitations, invitation] },
        };
      }),
      deleteInvitationEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.DELETE_INVITATION",
          payload: {
            invitationId: e.payload.invitationId,
            teamId: ctx.team.id,
          },
        });
      },
      handleDeleteInvitationSuccess: assign((ctx, e) => {
        const { invitationId } = e.payload;
        const { team } = ctx;
        return {
          team: { ...team, invitations: team.invitations.filter((i) => i.id !== invitationId) },
        };
      }),
      removeMemberEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.REMOVE_MEMBER",
          payload: {
            teamId: e.payload.teamId,
            memberId: e.payload.id,
          },
        });
      },
      handleRemoveMemberSuccess: assign((ctx, e) => {
        const { memberId } = e.payload;
        const { team } = ctx;
        return {
          team: { ...team, members: team.members.filter((m) => m.id !== memberId) },
        };
      }),
      updateMemberEpic: (ctx, e) => {
        epic.send({
          type: "TEAM.EPIC.UPDATE_MEMBER",
          payload: e.payload,
        });
      },
      handleUpdateMemberSuccess: assign((ctx, e) => {
        const member = e.payload;
        const { team } = ctx;
        return {
          team: { ...team, members: team.members.map((m) => (m.id === member.id ? member : m)) },
        };
      }),
      leaveTeamEpic: (ctx, event) => {
        epic.send({
          type: "TEAM.EPIC.LEAVE_TEAM",
          payload: {
            teamId: ctx.team.id,
            memberId: event.payload.memberId,
          },
        });
      },
      handleLeaveSuccess: assign((ctx, e) => {
        const memberId = e.payload;
        const { team } = ctx;
        return {
          team: { ...team, members: team.members.filter((m) => m.id !== memberId) },
        };
      }),
      handleLeaveFailure: (_) => {
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: { title: "Error Leaving Team", type: "error", description: "There was an error leaving the team" },
        });
      },
      refetchTeamDataEpic: (ctx) => {
        epic.send({
          type: "TEAM.EPIC.REFETCH_TEAM_DATA",
          payload: {
            teamId: ctx.team.id,
          },
        });
      },
      handleRefetchTeamDataSuccess: assign((ctx, e) => {
        const team = e.payload;
        return {
          team,
        };
      }),
    },
    guards: {
      isTeamId: (ctx, e) => ctx.team.id === e.payload.team?.id,
    },
  }),
);

export type Actor = ReturnType<typeof createTeamsActor>;

export const getTeamsActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
