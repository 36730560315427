import type { Team } from "@fscrypto/domain/team";
import { NavigationMenu, Text } from "@fscrypto/ui";
import { Link, useLocation, useParams } from "@remix-run/react";
import { useCurrentUser } from "~/features/current-user";
import { RewardDrawer } from "~/features/earn/components/rewards-drawer";
import { AddWalletModal } from "~/features/wallet-management/modal-add";
import { Header } from "@fscrypto/flip-kit/header";
import { ProfileManagerContainerRebrand } from "~/features/profile-manager/profile-manager-container";
import { useRewards } from "~/features/earn/state/use-rewards";
import { useCurrentProfileData } from "~/features/profile-manager/util/use-current-profile-data";

const itemStyle = "text-muted hover:bg-stroke/5 flex h-auto flex-col items-start rounded-lg p-2 text-xs inline-block";

const ListItem = ({ title, to, children, ...props }: { title: string; children?: React.ReactNode; to: string }) => {
  if (title === "Flipside Pro") return null;
  return (
    <li>
      <NavigationMenu.Link asChild>
        <Link to={to} className={itemStyle} {...props}>
          <Text size="sm" weight="medium" color="contrast">
            {title}
          </Text>
          {children ? (
            <Text size="xs" color="muted">
              {children}
            </Text>
          ) : null}
        </Link>
      </NavigationMenu.Link>
    </li>
  );
};

const ListItemExternal = ({
  title,
  href,
  children,
  ...props
}: {
  title: string;
  children?: React.ReactNode;
  href: string;
}) => {
  if (title === "Flipside Pro") return null;
  return (
    <li>
      <NavigationMenu.Link asChild>
        <a href={href} className={itemStyle} {...props} target="_blank" rel="noreferrer">
          <Text size="sm" weight="medium" color="contrast">
            {title}
          </Text>
          {children ? (
            <Text size="xs" color="muted">
              {children}
            </Text>
          ) : null}
        </a>
      </NavigationMenu.Link>
    </li>
  );
};

export const Shell = ({ children, teams }: { children: React.ReactNode; teams: Team[] }) => {
  const { currentUser } = useCurrentUser();
  const { pathname } = useLocation();
  const { ecosystem } = useParams();
  const isStudioPath =
    pathname.startsWith("/edit") || pathname.startsWith("/studio") || pathname.startsWith("/dashboards");
  const rewards = useRewards();
  const { currentProfile, isTeam } = useCurrentProfileData(teams);
  return (
    <div className="relative flex min-h-screen flex-col font-sans">
      <Header
        user={currentUser}
        team={isTeam ? (currentProfile as Team) : null}
        rewardCount={rewards.rewardOverview?.userRewards.length}
        renderMenuContent={<ProfileManagerContainerRebrand teams={teams} />}
      />
      <main className="relative flex h-full flex-1 flex-col">{children}</main>
      <AddWalletModal />
      <RewardDrawer userTeams={teams} />
    </div>
  );
};
