import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";
import { Team } from "@fscrypto/domain/team";
import { RefEvent } from "./types";
import { GlobalEventSelector } from "~/state/events";
import { User } from "@fscrypto/domain/user";

export interface Context {
  team: Team;
  filteredUsers: User[];
  callback: ((team: Context) => void) | null;
}

type Event = RefEvent | EpicEvent | GlobalEventSelector<"GLOBAL.PROFILE_MANAGER.ACCEPT_INVITATION">;

// All dependencies necessary for initializing the actor
export interface Deps {
  team?: Team;
  teamId: string;
}

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `teams-${string}`;

export const createTeamsMachine = (id: SystemKey, deps: Deps) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "idle",
    context: {
      team: deps.team!,
      filteredUsers: [],
      callback: null,
    },
    states: {
      idle: {
        on: {
          "TEAM.DELETE_TEAM": {
            actions: ["deleteTeamEpic", "persistCallback"],
            target: "deletingTeam",
          },
          "TEAM.TOGGLE_MANAGE_MEMBERS": {
            target: "managingMembers",
          },
          "TEAM.UPDATE_TEAM": {
            actions: ["updateTeamEpic", "persistCallback"],
            target: "updatingTeam",
          },
          "TEAM.LEAVE": {
            actions: ["leaveTeamEpic", "persistCallback"],
            target: "leaving",
          },
          "GLOBAL.PROFILE_MANAGER.ACCEPT_INVITATION": {
            actions: ["refetchTeamDataEpic"],
            cond: "isTeamId",
            target: "refetchingTeamData",
          },
        },
      },
      managingMembers: {
        id: "managingMembers",
        initial: "idleManage",
        on: {
          "TEAM.TOGGLE_MANAGE_MEMBERS": {
            target: "idle",
          },
          "TEAM.SET_USER_FILTER": {
            actions: ["searchUsersEpic"],
          },
          "TEAM.EPIC.SEARCH_USERS_SUCCESS": {
            actions: ["handleSearchUsersSuccess"],
          },
          "TEAM.CREATE_INVITATION": {
            actions: ["createInvitationEpic"],
            target: "managingMembers.creatingInvite",
          },
          "TEAM.UPDATE_INVITATION": {
            actions: ["updateInvitationEpic"],
            target: "managingMembers.updatingInvite",
          },
          "TEAM.DELETE_INVITATION": {
            actions: ["deleteInvitationEpic"],
            target: "managingMembers.deletingInvitation",
          },
          "TEAM.REMOVE_MEMBER": {
            actions: ["removeMemberEpic"],
            target: "managingMembers.removingMember",
          },
          "TEAM.UPDATE_MEMBER": {
            actions: ["updateMemberEpic"],
            target: "managingMembers.updatingMember",
          },
        },
        states: {
          idleManage: {},
          creatingInvite: {
            on: {
              "TEAM.EPIC.CREATE_INVITATION_SUCCESS": {
                actions: ["handleCreateInvitationSuccess"],
                target: "#managingMembers",
              },
              "TEAM.EPIC.CREATE_INVITATION_FAILURE": {
                actions: ["handleCreateInvitationFailure"],
                target: "#managingMembers",
              },
            },
          },
          updatingInvite: {
            on: {
              "TEAM.EPIC.UPDATE_INVITATION_SUCCESS": {
                actions: ["handleUpdateInvitationSuccess"],
                target: "#managingMembers",
              },
            },
          },
          deletingInvitation: {
            on: {
              "TEAM.EPIC.DELETE_INVITATION_SUCCESS": {
                actions: ["handleDeleteInvitationSuccess"],
                target: "#managingMembers",
              },
            },
          },
          removingMember: {
            on: {
              "TEAM.EPIC.REMOVE_MEMBER_SUCCESS": {
                actions: ["handleRemoveMemberSuccess"],
                target: "#managingMembers",
              },
            },
          },

          updatingMember: {
            on: {
              "TEAM.EPIC.UPDATE_MEMBER_SUCCESS": {
                actions: ["handleUpdateMemberSuccess"],
                target: "#managingMembers",
              },
            },
          },
        },
      },
      updatingTeam: {
        on: {
          "TEAM.EPIC.UPDATE_SUCCESS": {
            actions: ["handleUpdateTeamSuccess", "broadcastUpdate", "executeCallback"],
            target: "idle",
          },
        },
      },
      deletingTeam: {
        on: {
          "TEAM.EPIC.DELETE_SUCCESS": {
            actions: ["handleDeleteTeamSuccess", "executeCallback"],
            target: "idle",
          },
        },
      },
      leaving: {
        on: {
          "TEAM.EPIC.LEAVE_TEAM_SUCCESS": {
            actions: ["handleLeaveSuccess", "executeCallback"],
            target: "idle",
          },
          "TEAM.EPIC.LEAVE_TEAM_FAILURE": {
            actions: ["handleLeaveFailure"],
            target: "idle",
          },
        },
      },
      refetchingTeamData: {
        on: {
          "TEAM.EPIC.REFETCH_TEAM_DATA_SUCCESS": {
            actions: ["handleRefetchTeamDataSuccess"],
            target: "idle",
          },
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createTeamsMachine>["withConfig"]>["0"];
