import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";
import { Team, TeamEditOrCreate } from "@fscrypto/domain/team";
import { TeamInvitation } from "@fscrypto/domain/team-invitation";
import { GlobalEventSelector } from "~/state/events";

export interface Context {
  teams: Team[];
  invites: TeamInvitation[];
  callback: ((data: Context) => void) | null;
}

// A union of all events externally sent to the actor
type RefEvent =
  | {
      type: "PROFILE_MANAGER.DECLINE_PENDING_INVITATION";
      payload: {
        invitationId: string;
      };
    }
  | {
      type: "PROFILE_MANAGER.ACCEPT_PENDING_INVITATION";
      payload: { id: string };
    }
  | {
      type: "PROFILE_MANAGER.CREATE_TEAM";
      payload: TeamEditOrCreate;
      callback: Context["callback"];
    };

type Event = RefEvent | EpicEvent | GlobalEventSelector<"GLOBAL.TEAM.UPDATE">;

// All dependencies necessary for initializing the actor
export interface Deps {
  teams: Team[];
  invites: TeamInvitation[];
}

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `profile-manager`;

export const createProfileManagerMachine = (id: SystemKey, deps: Deps) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    context: {
      invites: deps?.invites,
      teams: deps?.teams,
      callback: null,
    },
    initial: "idle",
    states: {
      idle: {
        on: {
          "PROFILE_MANAGER.DECLINE_PENDING_INVITATION": {
            actions: ["declineInvitationEpic"],
            target: "decliningPendingInvitation",
          },
          "PROFILE_MANAGER.ACCEPT_PENDING_INVITATION": {
            actions: ["acceptInvitationEpic"],
            target: "acceptingPendingInvitation",
          },
          "PROFILE_MANAGER.CREATE_TEAM": {
            actions: ["createTeamEpic", "persistCallback"],
            target: "creatingTeam",
          },
          "GLOBAL.TEAM.UPDATE": {
            actions: ["updateTeam"],
          },
        },
      },
      creatingTeam: {
        on: {
          "PROFILE_MANAGER.EPIC.CREATE_SUCCESS": {
            actions: ["handleCreateTeamSuccess", "executeCallback"],
            target: "idle",
          },
        },
      },
      decliningPendingInvitation: {
        on: {
          "PROFILE_MANAGER.EPIC.DECLINE_INVITATION_SUCCESS": {
            actions: ["handleDeclinePendingInvitationSuccess"],
            target: "idle",
          },
        },
      },
      acceptingPendingInvitation: {
        on: {
          "PROFILE_MANAGER.EPIC.ACCEPT_INVITATION_SUCCESS": {
            actions: ["handleAcceptPendingInvitationSuccess", "broadcastAcceptInvitation"],
            target: "idle",
          },
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createProfileManagerMachine>["withConfig"]>["0"];
